import { translate } from '@fiverr-private/i18n-react';

export const FAQ_ITEM_TEST_ID = 'faq-item';
export const FAQ_LINK_TEST_ID = 'faq-link';

export const faqList = [
    'why_should_i',
    'what_makes_different',
    'how_much_costs',
    'how_can_i_communicate',
    'payment_methods',
    'what_paid_for',
    'who_do_i_contact',
    'can_i_work',
];

export const buildFaqSchema = ({ locale }) => ({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: faqList.map((item) => ({
        '@type': 'Question',
        name: translate(`logged_out_homepage.de.faq.${item}_q`, { locale }),
        acceptedAnswer: {
            '@type': 'Answer',
            text: translate(`logged_out_homepage.de.faq.${item}_a`, { locale }),
        },
    })),
});
