import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Icon } from '@fiverr-private/fit';
import * as icons from '@fiverr-private/icons';
import * as OrcaComponents from '@fiverr-private/orca';
import { I18n } from '@fiverr-private/i18n-react';
import { useIntersectionObserver } from '@fiverr-private/hooks';
import { getContext } from '@fiverr-private/fiverr_context';
import SafeComponent from '../../../logged_out_homepage/shared/SafeComponent';
import { LOHP_ROW, LOHP_INNER_ROW, MAX_WIDTH_CONTAINER } from '../../../logged_out_homepage/utils/constants';
import { sendMixPanelEvent } from '../../../logged_out_homepage/utils/biEvents';
import { MP_BRAND_VIDEO_CLICK } from '../../../logged_out_homepage/utils/biEvents/events/mixPanelEvents';

import { sourceInfo, sourcesByLocale } from './config';
import subtitles from './subtitles';

import styles from './style.module.scss';

const CheckIcon = () => {
  const l_check_circle = (icons as any).l_check_circle;

  return (
    <Icon className={styles.checkIcon} color="#7A7D85" size={24}>
      {l_check_circle}
    </Icon>
  );
};

const SellingProposition = () => {
  const { locale } = getContext();

  const videoRef = useRef<HTMLVideoElement>(null);
  const reportBi = () => {
    if (
      document.fullscreenElement ||
      (document as any).mozFullScreenElement ||
      (document as any).webkitFullscreenElement
    ) {
      sendMixPanelEvent({ eventName: MP_BRAND_VIDEO_CLICK });
    }
  };

  const { src, type, poster } = sourcesByLocale[locale];

  const sources = [
    {
      src,
      type,
    },
  ];

  useEffect(() => {
    videoRef?.current?.addEventListener('fullscreenchange', reportBi, false);
    videoRef?.current?.addEventListener('webkitfullscreenchange', reportBi, false);
    videoRef?.current?.addEventListener('mozfullscreenchange', reportBi, false);
    videoRef?.current?.addEventListener('MSFullscreenChange', reportBi, false);
  }, []);
  const [autoPlay, setAutoPlay] = useState(false);
  const VideoComp = (OrcaComponents as any).Video;
  const observableEntry = useIntersectionObserver(videoRef);
  const isIntersecting = observableEntry?.isIntersecting;
  useEffect(() => {
    if (isIntersecting && !autoPlay) {
      setAutoPlay(true);
      const observeTarget = videoRef.current as any;
      observeTarget.load();
    }
  }, [isIntersecting, autoPlay]);

  return (
    <div className={classNames(styles.sellingPropositionWrapper, LOHP_ROW, 'm-b-0')}>
      <div className={classNames('selling-proposition', LOHP_INNER_ROW, MAX_WIDTH_CONTAINER)}>
        <div className="grid-15 flex-items-center">
          <div className={classNames('col-6-md col-7-sm col-15-xs', styles.sellingText)}>
            <h2 className={styles.sellingTitle}>
              <I18n k="logged_out_homepage.de.selling_proposition.title" />
            </h2>
            <ul>
              <li className={styles.sellingItem}>
                <h6 className={styles.sellingItemTitle}>
                  <CheckIcon />
                  <I18n k="logged_out_homepage.de.selling_proposition.line1_title" />
                </h6>
                <p className={styles.sellingItemDescription}>
                  <I18n k="logged_out_homepage.de.selling_proposition.line1_description" />
                </p>
              </li>
              <li className={styles.sellingItem}>
                <h6 className={styles.sellingItemTitle}>
                  <CheckIcon />
                  <I18n k="logged_out_homepage.de.selling_proposition.line2_title" />
                </h6>
                <p className={styles.sellingItemDescription}>
                  <I18n k="logged_out_homepage.de.selling_proposition.line2_description" />
                </p>
              </li>
              <li className={styles.sellingItem}>
                <h6 className={styles.sellingItemTitle}>
                  <CheckIcon />
                  <I18n k="logged_out_homepage.de.selling_proposition.line3_title" />
                </h6>
                <p className={styles.sellingItemDescription}>
                  <I18n k="logged_out_homepage.de.selling_proposition.line3_description" />
                </p>
              </li>
              <li className={styles.sellingItem}>
                <h6 className={styles.sellingItemTitle}>
                  <CheckIcon />
                  <I18n k="logged_out_homepage.de.selling_proposition.line4_title" />
                </h6>
                <p className={styles.sellingItemDescription}>
                  <I18n k="logged_out_homepage.de.selling_proposition.line4_description" />
                </p>
              </li>
            </ul>
          </div>
          <div className={classNames(styles.sellingVideoWrapper, 'col-9-md col-8-sm col-15-xs')}>
            <VideoComp
              controls
              preload="none"
              autoPlay={autoPlay}
              ref={videoRef}
              playsInline
              muted
              loop
              source={sources}
              sourceInfo={sourceInfo}
              subtitles={subtitles}
              className={styles.sellingVideo}
              poster={poster}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { SellingProposition };
export default SafeComponent(SellingProposition);
