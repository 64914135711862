import graphicsDesign from '../../assets/categories/graphics-design.svg';
import digitalMarketing from '../../assets/categories/online-marketing.svg';
import writingTranslation from '../../assets/categories/writing-translation.svg';
import videoAnimation from '../../assets/categories/video-animation.svg';
import musicAudio from '../../assets/categories/music-audio.svg';
import programming from '../../assets/categories/programming.svg';
import business from '../../assets/categories/business.svg';
import lifestyle from '../../assets/categories/lifestyle.svg';
import data from '../../assets/categories/data.svg';
import photography from '../../assets/categories/photography.svg';

const categoriesData = [
    {
        categoryName: 'graphics_design',
        categoryId: 3,
        categorySlug: 'graphics-design',
        categoryImage: graphicsDesign,
    },
    {
        categoryName: 'digital_marketing',
        categoryId: 2,
        categorySlug: 'online-marketing',
        categoryImage: digitalMarketing,
    },
    {
        categoryName: 'writing_translation',
        categoryId: 5,
        categorySlug: 'writing-translation',
        categoryImage: writingTranslation,
    },
    {
        categoryName: 'video_animation',
        categoryId: 20,
        categorySlug: 'video-animation',
        categoryImage: videoAnimation,
    },
    {
        categoryName: 'music_audio',
        categoryId: 12,
        categorySlug: 'music-audio',
        categoryImage: musicAudio,
    },
    {
        categoryName: 'programming_tech',
        categoryId: 10,
        categorySlug: 'programming-tech',
        categoryImage: programming,
    },
    {
        categoryName: 'business',
        categoryId: 8,
        categorySlug: 'business',
        categoryImage: business,
    },
    {
        categoryName: 'lifestyle',
        categoryId: 7,
        categorySlug: 'lifestyle',
        categoryImage: lifestyle,
    },
    {
        categoryName: 'data',
        categoryId: 23,
        categorySlug: 'data',
        categoryImage: data,
    },
    {
        categoryName: 'photography',
        categoryId: 25,
        categorySlug: 'photography',
        categoryImage: photography,
    },
];

export default categoriesData;
