import React from 'react';
import classNames from 'classnames';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { Tag } from '@fiverr-private/fit';
import SafeComponent from '../../../logged_out_homepage/shared/SafeComponent';
import { LOHP_INNER_ROW, MAX_WIDTH_CONTAINER } from '../../../logged_out_homepage/utils/constants';
import { MP_CLICKED_TOP_PRIORITY } from '../../../logged_out_homepage/utils/biEvents/events/mixPanelEvents';
import { sendMixPanelEvent } from '../../../logged_out_homepage/utils/biEvents';
import { prioritiesMapByLocale, TOP_PRIORITIES_TITLE_TEST_ID, TOP_PRIORITIES_TAG_TEST_ID } from './config';
import styles from './styles.module.scss';

const TopPriorities = () => {
  const { locale } = getContext();

  const reportBi = (key) => () => {
    sendMixPanelEvent({ eventName: MP_CLICKED_TOP_PRIORITY, params: { element: translate(key) } });
  };

  const priorities = prioritiesMapByLocale[locale];

  return (
    <div className={styles.prioritiesContainer}>
      <div className={classNames(LOHP_INNER_ROW, MAX_WIDTH_CONTAINER)}>
        <h2 className={styles.prioritiesTitle} data-testid={TOP_PRIORITIES_TITLE_TEST_ID}>
          <I18n k="logged_out_homepage.de.top_priorities.title" />
        </h2>
        <ul className={styles.prioritiesList}>
          {priorities.map(({ key, link }) => (
            <li key={key} onClick={reportBi(key)} data-testid={TOP_PRIORITIES_TAG_TEST_ID}>
              <Tag
                color={(Tag as any).COLORS.GREY_1200}
                theme={(Tag as any).THEMES.HOLLOW}
                size={(Tag as any).SIZES.SMALL}
                href={link}
                className={styles.tag}
              >
                <I18n k={key} />
              </Tag>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SafeComponent(TopPriorities);
