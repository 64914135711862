import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import { imgPathWithTransforms, imgSrcSetFull } from '../../../logged_out_homepage/utils/image';
import {
    DPR_1,
    SLIDER_GRID_LRG,
    SLIDER_GRID_MED,
    SLIDER_GRID_SML,
    SLIDER_GRID_XSML,
} from '../../../logged_out_homepage/utils/constants';

const GRID_LRG_WIDTH = 255;
const GRID_MED_WIDTH = 305;
const GRID_SML_WIDTH = 360;
const GRID_XSML_WIDTH = 550;

const getTouchSrcSet = (filename) => imgSrcSetFull({ width: GRID_LRG_WIDTH, img: filename });
const getTouchSrc = (filename) => imgPathWithTransforms({ width: GRID_LRG_WIDTH, dpr: DPR_1, img: filename });
const getSrc = (filename) => imgPathWithTransforms({ width: GRID_XSML_WIDTH, dpr: DPR_1, img: filename });

const getPictureData = (filename) => [
    {
        srcSet: imgSrcSetFull({ width: GRID_LRG_WIDTH, img: filename }),
        media: SLIDER_GRID_LRG,
    },
    {
        srcSet: imgSrcSetFull({ width: GRID_MED_WIDTH, img: filename }),
        media: SLIDER_GRID_MED,
    },
    {
        srcSet: imgSrcSetFull({ width: GRID_SML_WIDTH, img: filename }),
        media: SLIDER_GRID_SML,
    },
    {
        srcSet: imgSrcSetFull({ width: GRID_XSML_WIDTH, img: filename }),
        media: SLIDER_GRID_XSML,
    },
];

export const SUB_CATEGORIES_NAMES = {
    AI_ARTISTS: 'ai_artists',
    CREATIVE_LOGO_DESIGN: 'creative_logo_design',
    WORDPRESS_SERVICES: 'wordpress_services',
    WEBSITE_DEVELOPMENT: 'website_development',
    VOICE_OVERS: 'voice_overs',
    ANIMATED_EXPLAINER: 'animated_explainer',
    SOCIAL_MEDIA: 'social_media',
    SEO_SERVICES: 'seo_services',
    ILLUSTRATION: 'illustration',
    TRANSLATION: 'translation',
    DATA_ENTRY: 'data_entry',
    BOOK_COVERS: 'book_covers',
    ECOMMERCE_MANAGEMENT: 'ecommerce_management',
    IMAGE_EDITING: 'image_editing',
};

const SUB_CATEGORIES = {
    [SUB_CATEGORIES_NAMES.AI_ARTISTS]: ({ locale }) => {
        const AI_ARTISTS = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161247/ai-artists-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '79378eccf7a43039b30e807fdc73f087-1689604975959/ai-artists-2x.png',
        };

        const SRC = AI_ARTISTS[locale];

        return {
            name: SUB_CATEGORIES_NAMES.AI_ARTISTS,
            subCategoryId: 526,
            categorySlug: 'graphics-design',
            subCategorySlug: 'ai-art-prompt',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.CREATIVE_LOGO_DESIGN]: ({ locale }) => {
        const LOGO_DESIGN = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161257/logo-design-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: 'a795b343a3d8533c3f57f8195e1fac26-1689604654203/logo-design-2x.png',
        };

        const SRC = LOGO_DESIGN[locale];

        return {
            name: SUB_CATEGORIES_NAMES.CREATIVE_LOGO_DESIGN,
            subCategoryId: 49,
            categorySlug: 'graphics-design',
            subCategorySlug: 'creative-logo-design',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.WORDPRESS_SERVICES]: ({ locale }) => {
        const WORDPRESS = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161257/wordpress-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '76a7857fe5d8df4d50d995c9582bdf9e-1689605043532/wordpress-2x.png',
        };

        const SRC = WORDPRESS[locale];

        return {
            name: SUB_CATEGORIES_NAMES.WORDPRESS_SERVICES,
            subCategoryId: 91,
            categorySlug: 'programming-tech',
            subCategorySlug: 'website-development',
            nestedSubCategorySlug: 'wordpress-development',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.VOICE_OVERS]: ({ locale }) => {
        const VOICE_OVER = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161253/voice-over-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '6d2686026e20bab48384dc0f9acaadd4-1689605220236/voiceover-2x.png',
        };

        const SRC = VOICE_OVER[locale];

        return {
            name: SUB_CATEGORIES_NAMES.VOICE_OVERS,
            subCategoryId: 13,
            categorySlug: 'music-audio',
            subCategorySlug: 'voice-overs',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.ECOMMERCE_MANAGEMENT]: ({ locale }) => {
        const ECOMMERCE_MANAGEMENT = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                '894fca57bacf78fd8f52b8e66992ecb3-1690384040023/ecommerce-management-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]:
                '803122869deaaa7c4a53078f2faff243-1689605678912/ecommerce-management-2x.png',
        };

        const SRC = ECOMMERCE_MANAGEMENT[locale];

        return {
            name: SUB_CATEGORIES_NAMES.ECOMMERCE_MANAGEMENT,
            subCategoryId: 423,
            categorySlug: 'business',
            subCategorySlug: 'ecommerce-management',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.IMAGE_EDITING]: ({ locale }) => {
        const IMAGE_EDITING = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                '894fca57bacf78fd8f52b8e66992ecb3-1690384040038/image-editing-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]:
                '50076461f1df604b1bee346076997140-1689605749600/image-editing-2x.png',
        };

        const SRC = IMAGE_EDITING[locale];

        return {
            name: SUB_CATEGORIES_NAMES.IMAGE_EDITING,
            subCategoryId: 53,
            categorySlug: 'graphics-design',
            subCategorySlug: 'image-editing',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.ANIMATED_EXPLAINER]: ({ locale }) => {
        const ANIMATED_EXPLAINER = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]:
                '7ead3b2056987e6fa3aad69cf897a50b-1690383161245/animated-explainer-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]:
                'd6a68b4b7ed4c9d8ded59c9707313b8f-1689605312002/animated-explainer-2x.png',
        };

        const SRC = ANIMATED_EXPLAINER[locale];

        return {
            name: SUB_CATEGORIES_NAMES.ANIMATED_EXPLAINER,
            subCategoryId: 228,
            categorySlug: 'video-animation',
            subCategorySlug: 'animated-explainer-videos',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.SOCIAL_MEDIA]: ({ locale }) => {
        const SOCIAL = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161249/social-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: 'faf41b5857a9ec20e5b539a1a0be9c7b-1689605119319/social-2x.png',
        };

        const SRC = SOCIAL[locale];

        return {
            name: SUB_CATEGORIES_NAMES.SOCIAL_MEDIA,
            subCategoryId: 67,
            categorySlug: 'online-marketing',
            subCategorySlug: 'social-marketing',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.SEO_SERVICES]: ({ locale }) => {
        const SEO = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '27f914ed7984fdd2d55aa1fb5e74bd6a-1690384243592/seo-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '55570df37f9341385981a3cbae1a9403-1689605443850/seo-2x.png',
        };

        const SRC = SEO[locale];

        return {
            name: SUB_CATEGORIES_NAMES.SEO_SERVICES,
            subCategoryId: 65,
            categorySlug: 'online-marketing',
            subCategorySlug: 'seo-services',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.ILLUSTRATION]: ({ locale }) => {
        const ILLUSTRATION = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161236/illustration-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '611f0230eee2eeefbff65e393c952e7a-1689605381675/illustration-2x.png',
        };

        const SRC = ILLUSTRATION[locale];

        return {
            name: SUB_CATEGORIES_NAMES.ILLUSTRATION,
            subCategoryId: 50,
            categorySlug: 'graphics-design',
            subCategorySlug: 'digital-illustration',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.TRANSLATION]: ({ locale }) => {
        const TRANSLATION = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161247/translation-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: 'c72f1b801d5eb2ac597c9f1f042c70a7-1689605559755/translation-2x.png',
        };

        const SRC = TRANSLATION[locale];

        return {
            name: SUB_CATEGORIES_NAMES.TRANSLATION,
            subCategoryId: 108,
            categorySlug: 'writing-translation',
            subCategorySlug: 'quality-translation-services',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.DATA_ENTRY]: ({ locale }) => {
        const DATA_ENTRY = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161248/data-entry-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: 'a710f4bd61f620eaa3ce32581b4eaa15-1689605611316/data-entry-2x.png',
        };

        const SRC = DATA_ENTRY[locale];

        return {
            name: SUB_CATEGORIES_NAMES.DATA_ENTRY,
            subCategoryId: 456,
            categorySlug: 'data',
            subCategorySlug: 'data-entry',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
    [SUB_CATEGORIES_NAMES.BOOK_COVERS]: ({ locale }) => {
        const BOOK_COVERS = {
            [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: '7ead3b2056987e6fa3aad69cf897a50b-1690383161238/book-covers-2x.png',
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: '3563e10e42e58fcdb8d119574f4d383d-1689605502570/book-covers-2x.png',
        };

        const SRC = BOOK_COVERS[locale];

        return {
            name: SUB_CATEGORIES_NAMES.BOOK_COVERS,
            subCategoryId: 51,
            nestedSubCategoryId: 2318,
            categorySlug: 'graphics-design',
            subCategorySlug: 'book-design',
            nestedSubCategorySlug: 'cover',
            image: {
                src: getSrc(SRC),
                touchSrcSet: getTouchSrcSet(SRC),
                touchSrc: getTouchSrc(SRC),
            },
            picture: getPictureData(SRC),
        };
    },
};

const SUB_CATEGORIES_LIST = {
    [SUPPORTED_FIVERR_LOCALES_KEYS.EN_US]: [
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.AI_ARTISTS]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.CREATIVE_LOGO_DESIGN]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.WORDPRESS_SERVICES]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.VOICE_OVERS]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.ANIMATED_EXPLAINER]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.SOCIAL_MEDIA]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.SEO_SERVICES]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.ILLUSTRATION]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.TRANSLATION]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.DATA_ENTRY]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.BOOK_COVERS]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.EN_US }),
    ],
    [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: [
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.AI_ARTISTS]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.CREATIVE_LOGO_DESIGN]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.WORDPRESS_SERVICES]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.VOICE_OVERS]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.ANIMATED_EXPLAINER]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.SOCIAL_MEDIA]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.SEO_SERVICES]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.ILLUSTRATION]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.TRANSLATION]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.ECOMMERCE_MANAGEMENT]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
        SUB_CATEGORIES[SUB_CATEGORIES_NAMES.IMAGE_EDITING]({ locale: SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE }),
    ],
};

export const getSubCategories = ({ locale }) => {
    if (locale === SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE) {
        return SUB_CATEGORIES_LIST[SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE];
    }

    return SUB_CATEGORIES_LIST[SUPPORTED_FIVERR_LOCALES_KEYS.EN_US];
};
