import { imgPathWithTransforms } from '../../../logged_out_homepage/utils/image';
import { DPR_1 } from '../../../logged_out_homepage/utils/constants';

const XLARGE_WIDTH = 450;

const GUIDE_CREATE_WEBSITE = 'bee1e9b1fc2318af3d4048cf5a474a94-1615801150395/créer%20un%20site%20internet.jpg';
const GUIDE_PROFESSIONAL_LOGO =
    'd1aadf73fa1717f8865af5fecae8711f-1615729047237/comment%20creer%20un%20logo%20-%20Fiverr%20Guides.jpg';
const GUIDE_CREATE_APP = 'ba6f10b7a66b356fc05b591c8c819d2c-1615726781641/shutterstock_1601487100.jpg';
const GUIDE_SITE_IN_10_STEPS = '9d5610090c04e57d495dd07f5d535267-1615736094723/Crear%20pagina%20web%20-%20main.jpg';
const GUIDE_LOGO_IN_10_STEPS = 'cb0a89b13a9df6885c4ca79d1954f2f6-1615735631886/Logo%20design%20es-main.jpg';
const GUIDE_SOCIAL_MEDIA_FOR_A_BUSINESS =
    'ec483ad280d6d742b011790582d22e8b-1616355878936/Que%20son%20las%20redes%20sociales-main.jpg';
const GUIDE_DE_LOHP_CORPORATE_DESIGN =
    'e5fc61351e6a5b1b5854d5a4058fbfed-1679494361808/de-lohp-guide-corporate-design.jpg';
const GUIDE_DE_LOHP_ONLINE_STORE = 'e5fc61351e6a5b1b5854d5a4058fbfed-1679494361808/de-lohp-guide-online-store.jpg';
const GUIDE_DE_LOHP_PODCAST = 'e5fc61351e6a5b1b5854d5a4058fbfed-1679494361815/de-lohp-guide-podcast.jpg';

const guideImagePath = (img) => imgPathWithTransforms({ width: XLARGE_WIDTH, dpr: DPR_1, img });

export const LOCALE_GUIDES = {
    'en-US': [
        {
            title: 'Start a side business',
            image_alt: 'Start a side business',
            article_link: '/resources/guides/business/side-hustle-ideas',
            image_link: guideImagePath('0c7c1b07050e6ea2a0901861b48b6264-1658846941284/side%20hustle.jpeg'),
        },
        {
            title: 'Ecommerce business Ideas',
            image_alt: 'Ecommerce business Ideas',
            article_link: '/resources/guides/business/ecommerce-business-ideas',
            image_link: guideImagePath(
                '687d698a96f4eef875648319685ffeed-1687027332007/1685561103924-12profitableecommercebusinessideasyoucanstarttoday.jpg'
            ),
        },
        {
            title: 'Start an online business and work from home',
            image_alt: 'Start an online business and work from home',
            article_link: '/resources/guides/business/start-online-business-and-work-from-home',
            image_link: guideImagePath(
                '5907f56b0e099c84efe5f480840f43a2-1593446948907/home%20based%20online%20business-fiverr.jpg'
            ),
        },
        {
            title: 'Build a website from scratch',
            image_alt: 'Build a website from scratch',
            article_link: '/resources/guides/programming-tech/build-a-website',
            image_link: guideImagePath(
                '8b1dcc55f5c8582b04aee0b995ae5327-1683590934905/1682363178357-Howtobuildawebsitefromscratch.jpg'
            ),
        },
        {
            title: 'Grow your business with AI',
            image_alt: 'Grow your business with AI',
            article_link: '/resources/guides/writing-and-copywriting/ai-in-content-marketing',
            image_link: guideImagePath('309ac0d5d01de83b832e421b316352a8-1690708285578/05%20-%20Article%20Cover.jpg'),
        },
        {
            title: 'Create a logo for your business',
            image_alt: 'Create a logo for your business',
            article_link: '/resources/guides/graphic-design/how-to-create-business-logo',
            image_link: guideImagePath(
                '10f680cb84a2f3ef4473ecfdede3a1ba-1593438129320/business%20logo%20design-fiverr%20guide.jpg'
            ),
        },
    ],
    'de-DE': [
        {
            article_link: '/resources/guides/design-gestaltung/corporate-design-erstellen',
            image_link: guideImagePath(GUIDE_DE_LOHP_CORPORATE_DESIGN),
            title: 'Corporate Design erstellen: ein kompletter Leitfaden',
        },
        {
            article_link: '/resources/guides/entrepreneurship/online-shop-eroeffnen',
            image_link: guideImagePath(GUIDE_DE_LOHP_ONLINE_STORE),
            title: 'Den eigenen Online-Shop eröffnen – das solltest du wissen',
        },
        {
            article_link: '/resources/guides/audio-musikproduktion/podcast-erstellen',
            image_link: guideImagePath(GUIDE_DE_LOHP_PODCAST),
            title: 'Podcast erstellen: Wissenswertes rund ums Podcasting',
        },
    ],
    'fr-FR': [
        {
            article_link: 'resources/guides/tech-developpement/comment-creer-un-site-internet',
            image_link: guideImagePath(GUIDE_CREATE_WEBSITE),
            title: 'Comment créer un site internet',
        },
        {
            article_link: '/resources/guides/design/comment-creer-un-logo',
            image_link: guideImagePath(GUIDE_PROFESSIONAL_LOGO),
            title: 'Comment créer un logo',
        },
        {
            article_link: '/resources/guides/tech-developpement/comment-creer-une-application',
            image_link: guideImagePath(GUIDE_CREATE_APP),
            title: 'Comment créer une application en 6 étapes',
        },
    ],
    es: [
        {
            article_link: '/resources/guides/desarrollo-web-movil/como-crear-una-pagina-web',
            image_link: guideImagePath(GUIDE_SITE_IN_10_STEPS),
            title: 'Cómo crear una página web',
        },
        {
            article_link: 'resources/guides/diseno/como-crear-un-logo',
            image_link: guideImagePath(GUIDE_LOGO_IN_10_STEPS),
            title: 'Cómo crear un logo memorable',
        },
        {
            article_link: 'resources/guides/marketing-publicidad/que-son-las-redes-sociales',
            image_link: guideImagePath(GUIDE_SOCIAL_MEDIA_FOR_A_BUSINESS),
            title: 'Impulsa tu negocio con redes sociales',
        },
    ],
};
