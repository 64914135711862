import React from 'react';
import { string } from 'prop-types';
import 'lazysizes';

import './style.scss';

const LazyImage = ({ alt, src, srcSet }) => <img alt={alt} data-src={src} data-srcset={srcSet} className="lazyload" />;

LazyImage.propTypes = {
    alt: string,
    src: string,
    srcSet: string,
};

export default LazyImage;
