import React from 'react';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/fiverr_context';
import SafeComponent from '../../../logged_out_homepage/shared/SafeComponent';
import { LOHP_ROW, MAX_WIDTH_CONTAINER } from '../../../logged_out_homepage/utils/constants';
import Card from './Card';
import categoriesData from './config';

import './style.scss';

const Categories = () => (
    <div className={classNames('main-categories', LOHP_ROW, MAX_WIDTH_CONTAINER)}>
        <h2>
            <I18n k="logged_out_homepage.categories.title" />
        </h2>
        <ul className="categories-list">
            {categoriesData.map((categoryData, index) => (
                <Card key={index} data-testid="category-card" position={index + 1} {...categoryData} />
            ))}
        </ul>
    </div>
);

export { Categories };
export default SafeComponent(Categories);
