import React from 'react';
import SearchBar from '@fiverr-private/search_bar';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n, translate } from '@fiverr-private/i18n-react';

import styles from './styles.module.scss';

export const Header = () => {
  const { isTouch } = getContext();

  return (
    <div className={styles.header}>
      <h1 className={styles.headerTitle}>
        <I18n
          k="logged_out_homepage.hero.title"
          templates={{
            i: (text) => <i className={styles.headerTitleItalic}>{text}</i>,
          }}
        />
      </h1>
      <div className={styles.searchWrapper}>
        <SearchBar
          placeholder={translate('logged_out_homepage.de.hero.what_r_u_looking_for')}
          source="main_banner"
          submitButtonType={isTouch ? 'text' : 'icon'}
        />
      </div>
    </div>
  );
};
