import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { getSubCategoryUrl } from '../../../../logged_out_homepage/utils/url';
import { SOURCE, SubCategories } from '../config';

import styles from './styles.module.scss';

interface CategoryItemProps {
  category: SubCategories;
}

export const CategoryItem = ({ category: { categorySlug, subCategorySlug, icon, name } }: CategoryItemProps) => {
  const params = { source: SOURCE };
  const url = getSubCategoryUrl({ categorySlug, subCategorySlug, params });
  return (
    <a href={url} className={styles.categoryItem}>
      {icon}
      <div className="m-t-12">
        <I18n k={`logged_out_homepage.subcategory_carousel.${name}.sub`} />
      </div>
    </a>
  );
};
