import React from 'react';
import classNames from 'classnames';
import { LOHP_ROW, MAX_WIDTH_CONTAINER } from '../../../logged_out_homepage/utils/constants';
import { subCategories } from './config';
import { CategoryItem } from './CategoryItem';

import styles from './styles.module.scss';

export const CategoryIcons = () => (
  <div className={classNames(LOHP_ROW, MAX_WIDTH_CONTAINER)}>
    <div className={styles.categoryList}>
      {Object.values(subCategories).map((category) => (
        <CategoryItem key={category.name} category={category} />
      ))}
    </div>
  </div>
);
