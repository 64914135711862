import React from 'react';
import { I18n, translate } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import { CollapsibleGroup, Collapsible } from '@fiverr-private/fit';
import SafeComponent from '../../../logged_out_homepage/shared/SafeComponent';
import { sendMixPanelEvent } from '../../../logged_out_homepage/utils/biEvents';
import { MP_FAQ_CLICKED } from '../../../logged_out_homepage/utils/biEvents/events/mixPanelEvents';
import { LOHP_ROW } from '../../../logged_out_homepage/utils/constants';
import { faqList, FAQ_ITEM_TEST_ID, FAQ_LINK_TEST_ID } from './config';

import styles from './styles.module.scss';

const FrequentlyAskedQuestions = () => {
  const reportBi = (element) => () => {
    sendMixPanelEvent({ eventName: MP_FAQ_CLICKED, params: { element } });
  };

  return (
    <div className={LOHP_ROW}>
      <div className={styles.faqTitle}>
        <I18n k="logged_out_homepage.de.faq.title" />
      </div>
      <CollapsibleGroup>
        {faqList.map((faqItem, index) => (
          <Collapsible
            key={index}
            onToggle={reportBi(translate(`logged_out_homepage.de.faq.${faqItem}_q`))}
            titleWrapperClassName={styles.faqItemTitle}
            className={styles.faqItem}
            chevronClassName="m-l-12"
            contentClassName="tbody-6"
            title={
              <div data-testid={FAQ_ITEM_TEST_ID}>
                <I18n k={`logged_out_homepage.de.faq.${faqItem}_q`} />
              </div>
            }
          >
            <I18n k={`logged_out_homepage.de.faq.${faqItem}_a`} />
          </Collapsible>
        ))}
      </CollapsibleGroup>
      <div className={styles.faqFooter}>
        <I18n
          k="logged_out_homepage.de.faq.footer"
          templates={{
            link: (text) => (
              <a
                href={pathfinder('help_center_homepage')}
                className="co-green text-bold"
                data-testid={FAQ_LINK_TEST_ID}
              >
                {text}
              </a>
            ),
          }}
        />
      </div>
    </div>
  );
};

export default SafeComponent(FrequentlyAskedQuestions);
