import { DPR_1 } from '../../../logged_out_homepage/utils/constants';
import { imgPathWithTransforms, imgSrcSetFull } from '../../../logged_out_homepage/utils/image';

const WIDTH = 200;

const OMR = '48a313bb4f8ac73bf20bec729d094665-1689848523345/omr.png';
const GOOGLE = 'd0e473d657ce3e62383416bbd3deba6a-1689848508269/google.png';
const BITKOM = '6af1c62a66e5d9c90e0abde28ee3f357-1689848483454/bitkom.png';
const PAYPAL = 'fd71eaf076d35694ca547dcbbb1f8e7d-1689848552407/paypal.png';
const P_AND_G = '247719bd86e360a3a9db43148dc0d74b-1689848537449/pandg.png';
const NETFLIX = '674a089b506e8f0e168b5fc5bb2d126c-1726573392738/netflix.svg';

export const COMPANY_LOGOS = [
    {
        alt: 'OMR',
        srcSet: imgSrcSetFull({ width: WIDTH, img: OMR, retinaImg: null }),
        src: imgPathWithTransforms({ width: WIDTH, dpr: DPR_1, img: OMR }),
    },
    {
        alt: 'Google',
        srcSet: imgSrcSetFull({ width: WIDTH, img: GOOGLE, retinaImg: null }),
        src: imgPathWithTransforms({ width: WIDTH, dpr: DPR_1, img: GOOGLE }),
    },
    {
        alt: 'Netflix',
        srcSet: imgSrcSetFull({ width: WIDTH, img: NETFLIX, retinaImg: null }),
        src: imgPathWithTransforms({ width: WIDTH, dpr: DPR_1, img: NETFLIX }),
    },
    {
        alt: 'PayPal',
        srcSet: imgSrcSetFull({ width: WIDTH, img: PAYPAL, retinaImg: null }),
        src: imgPathWithTransforms({ width: WIDTH, dpr: DPR_1, img: PAYPAL }),
    },
    {
        alt: 'P&G',
        srcSet: imgSrcSetFull({ width: WIDTH, img: P_AND_G, retinaImg: null }),
        src: imgPathWithTransforms({ width: WIDTH, dpr: DPR_1, img: P_AND_G }),
    },
];

export const COMPANY_LOGOS_DE = [
    {
        alt: 'OMR',
        srcSet: imgSrcSetFull({ width: WIDTH, img: OMR, retinaImg: null }),
        src: imgPathWithTransforms({ width: WIDTH, dpr: DPR_1, img: OMR }),
    },
    {
        alt: 'Google',
        srcSet: imgSrcSetFull({ width: WIDTH, img: GOOGLE, retinaImg: null }),
        src: imgPathWithTransforms({ width: WIDTH, dpr: DPR_1, img: GOOGLE }),
    },
    {
        alt: 'Bitkom',
        srcSet: imgSrcSetFull({ width: WIDTH, img: BITKOM, retinaImg: null }),
        src: imgPathWithTransforms({ width: WIDTH, dpr: DPR_1, img: BITKOM }),
    },
    {
        alt: 'PayPal',
        srcSet: imgSrcSetFull({ width: WIDTH, img: PAYPAL, retinaImg: null }),
        src: imgPathWithTransforms({ width: WIDTH, dpr: DPR_1, img: PAYPAL }),
    },
    {
        alt: 'P&G',
        srcSet: imgSrcSetFull({ width: WIDTH, img: P_AND_G, retinaImg: null }),
        src: imgPathWithTransforms({ width: WIDTH, dpr: DPR_1, img: P_AND_G }),
    },
];
