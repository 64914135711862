import React from 'react';
import classNames from 'classnames';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import { COMPANY_LOGOS, COMPANY_LOGOS_DE } from './config';
import { CompanyLogo } from './CompanyLogo';

import styles from './styles.module.scss';

export const TrustedBy = () => {
  const { locale } = getContext();

  const logos = locale === SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE ? COMPANY_LOGOS_DE : COMPANY_LOGOS;

  return (
    <div className={classNames(styles.trustedBy)}>
      <span className={classNames(styles.trustedByText)}>
        <I18n k="logged_out_homepage.trusted_by.title" />:
      </span>
      <ul className={classNames(styles.trustedByList)}>
        {logos.map((company, index) => (
          <CompanyLogo key={index} alt={company.alt} src={company.src} srcSet={company.srcSet} />
        ))}
      </ul>
    </div>
  );
};
