import React, { ReactNode } from 'react';
import {
  CategoryAiLargeIcon,
  CategoryIllustrationLargeIcon,
  CategoryLogoDesignLargeIcon,
  CategorySeoLargeIcon,
  CategorySocialMediaLargeIcon,
  CategoryTranslationLargeIcon,
  CategoryVideoExplainerLargeIcon,
  CategoryVoiceOverLargeIcon,
  CategoryWebDevelopmentLargeIcon,
} from '@fiverr-private/icons';
import { colorGrey1100 } from '@fiverr-private/sass/helpers/index';
import { SUB_CATEGORIES_NAMES } from '../SubcategoryCarousel/config';

export const SOURCE = 'subcat_logo';

export interface SubCategories {
  name: string;
  subCategoryId: number;
  categorySlug: string;
  subCategorySlug: string;
  icon: ReactNode;
}

export const subCategories = {
  [SUB_CATEGORIES_NAMES.CREATIVE_LOGO_DESIGN]: {
    name: SUB_CATEGORIES_NAMES.CREATIVE_LOGO_DESIGN,
    subCategoryId: 49,
    categorySlug: 'graphics-design',
    subCategorySlug: 'creative-logo-design',
    icon: <CategoryLogoDesignLargeIcon size={32} color={colorGrey1100} />,
  },
  [SUB_CATEGORIES_NAMES.AI_ARTISTS]: {
    name: SUB_CATEGORIES_NAMES.AI_ARTISTS,
    subCategoryId: 526,
    categorySlug: 'graphics-design',
    subCategorySlug: 'ai-art-prompt',
    icon: <CategoryAiLargeIcon size={32} color={colorGrey1100} />,
  },
  [SUB_CATEGORIES_NAMES.WEBSITE_DEVELOPMENT]: {
    name: SUB_CATEGORIES_NAMES.WORDPRESS_SERVICES,
    subCategoryId: 91,
    categorySlug: 'programming-tech',
    subCategorySlug: 'website-development',
    icon: <CategoryWebDevelopmentLargeIcon size={32} color={colorGrey1100} />,
  },
  [SUB_CATEGORIES_NAMES.SOCIAL_MEDIA]: {
    name: SUB_CATEGORIES_NAMES.SOCIAL_MEDIA,
    subCategoryId: 67,
    categorySlug: 'online-marketing',
    subCategorySlug: 'social-marketing',
    icon: <CategorySocialMediaLargeIcon size={32} color={colorGrey1100} />,
  },
  [SUB_CATEGORIES_NAMES.VOICE_OVERS]: {
    name: SUB_CATEGORIES_NAMES.VOICE_OVERS,
    subCategoryId: 13,
    categorySlug: 'music-audio',
    subCategorySlug: 'voice-overs',
    icon: <CategoryVoiceOverLargeIcon size={32} color={colorGrey1100} />,
  },
  [SUB_CATEGORIES_NAMES.ANIMATED_EXPLAINER]: {
    name: SUB_CATEGORIES_NAMES.ANIMATED_EXPLAINER,
    subCategoryId: 228,
    categorySlug: 'video-animation',
    subCategorySlug: 'animated-explainer-videos',
    icon: <CategoryVideoExplainerLargeIcon size={32} color={colorGrey1100} />,
  },
  [SUB_CATEGORIES_NAMES.ILLUSTRATION]: {
    name: SUB_CATEGORIES_NAMES.ILLUSTRATION,
    subCategoryId: 50,
    categorySlug: 'graphics-design',
    subCategorySlug: 'digital-illustration',
    icon: <CategoryIllustrationLargeIcon size={32} color={colorGrey1100} />,
  },
  [SUB_CATEGORIES_NAMES.SEO_SERVICES]: {
    name: SUB_CATEGORIES_NAMES.SEO_SERVICES,
    subCategoryId: 65,
    categorySlug: 'online-marketing',
    subCategorySlug: 'seo-services',
    icon: <CategorySeoLargeIcon size={32} color={colorGrey1100} />,
  },
  [SUB_CATEGORIES_NAMES.TRANSLATION]: {
    name: SUB_CATEGORIES_NAMES.TRANSLATION,
    subCategoryId: 108,
    categorySlug: 'writing-translation',
    subCategorySlug: 'quality-translation-services',
    icon: <CategoryTranslationLargeIcon size={32} color={colorGrey1100} />,
  },
};
