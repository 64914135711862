import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

interface CompanyLogoProps {
  alt: string;
  srcSet: string;
  src: string;
}

export const CompanyLogo = ({ alt, srcSet, src }: CompanyLogoProps) => (
  <li className={classNames(styles.trustedByListItem)}>
    <img className={styles.trustedByImg} src={src} srcSet={srcSet} alt={alt} />
  </li>
);
