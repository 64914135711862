import { imgSrcSetFull, imgPathWithTransforms } from '../../../logged_out_homepage/utils/image';
import { XLRG_IMAGE, LRG_IMAGE, MED_IMAGE, DPR_1 } from '../../../logged_out_homepage/utils/constants';

const XLARGE_WIDTH = 870;
const LARGE_WIDTH = 585;
const MED_WIDTH = 800;

const ImgPath = '51c35c7cecf75e6a5a0110d27909a2f5-1690202609364/EN.png';
const germanImgPath = '51c35c7cecf75e6a5a0110d27909a2f5-1690202609356/DE.png';

export const defaultPictureData = [
    {
        srcSet: imgSrcSetFull({
            width: XLARGE_WIDTH,
            img: ImgPath,
        }),
        media: XLRG_IMAGE,
    },
    {
        srcSet: imgSrcSetFull({
            width: LARGE_WIDTH,
            img: ImgPath,
        }),
        media: LRG_IMAGE,
    },
    {
        srcSet: imgSrcSetFull({
            width: MED_WIDTH,
            img: ImgPath,
        }),
        media: MED_IMAGE,
    },
];

export const germanPictureData = [
    {
        srcSet: imgSrcSetFull({
            width: XLARGE_WIDTH,
            img: germanImgPath,
        }),
        media: XLRG_IMAGE,
    },
    {
        srcSet: imgSrcSetFull({
            width: LARGE_WIDTH,
            img: germanImgPath,
        }),
        media: LRG_IMAGE,
    },
    {
        srcSet: imgSrcSetFull({
            width: MED_WIDTH,
            img: germanImgPath,
        }),
        media: MED_IMAGE,
    },
];

export const defaultCopyData = {
    title: 'logged_out_homepage.business_section.title',
    list: [
        {
            title: 'logged_out_homepage.business_section.list.pro.title',
            content: 'logged_out_homepage.business_section.list.pro.content',
        },
        {
            title: 'logged_out_homepage.business_section.list.certified.title',
            content: 'logged_out_homepage.business_section.list.certified.content',
        },
        {
            title: 'logged_out_homepage.business_section.list.enterprise.title',
            content: 'logged_out_homepage.business_section.list.enterprise.content',
        },
    ],
    button: 'logged_out_homepage.business_section.button',
    alt: 'logged_out_homepage.business_section.alt',
};

export const germanCopyData = {
    title: 'logged_out_homepage.de.business_section.title',
    list: [
        {
            title: 'logged_out_homepage.de.business_section.list.pro.title',
            content: 'logged_out_homepage.de.business_section.list.pro.content',
        },
        {
            title: 'logged_out_homepage.de.business_section.list.certified.title',
            content: 'logged_out_homepage.de.business_section.list.certified.content',
        },
        {
            title: 'logged_out_homepage.de.business_section.list.enterprise.title',
            content: 'logged_out_homepage.de.business_section.list.enterprise.content',
        },
    ],
    button: 'logged_out_homepage.de.business_section.button',
    alt: 'logged_out_homepage.de.business_section.alt',
};

export const defaultImgSrc = imgPathWithTransforms({ width: XLARGE_WIDTH, dpr: DPR_1, img: ImgPath });
export const germanImgSrc = imgPathWithTransforms({ width: XLARGE_WIDTH, dpr: DPR_1, img: germanImgPath });
