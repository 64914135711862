import React from 'react';
import { string, number, arrayOf, shape } from 'prop-types';
import classNames from 'classnames';
import { getContext, I18n, I18nProvider } from '@fiverr-private/fiverr_context';
import { translate } from '@fiverr-private/i18n-react';
import { getNestedSubCategoryUrl, getSubCategoryUrl } from '../../../../logged_out_homepage/utils/url';
import { MP_CAROUSEL_CLICKED } from '../../../../logged_out_homepage/utils/biEvents/events/mixPanelEvents';
import { sendMixPanelEvent } from '../../../../logged_out_homepage/utils/biEvents';
import { TouchImage } from './TouchImage';

import './style.scss';

const source = 'hplo_subcat_first_step';

const SubCategory = ({ name, categorySlug, subCategorySlug, nestedSubCategorySlug, picture, image, index }) => {
    const { isTouch } = getContext();
    const pos = index + 1;
    const subCategoryNameKey = `logged_out_homepage.subcategory_carousel.${name}.sub`;
    const wrapperClassName = classNames('subcategory-wrapper', { touch: isTouch });

    const params = { source, pos };
    const url = nestedSubCategorySlug
        ? getNestedSubCategoryUrl({ categorySlug, subCategorySlug, nestedSubCategorySlug, params })
        : getSubCategoryUrl({ categorySlug, subCategorySlug, params });

    const handleAfterChange = () => {
        sendMixPanelEvent({
            eventName: MP_CAROUSEL_CLICKED,
            params: { order: pos, element: translate(subCategoryNameKey) },
        });
    };

    return (
        <div className={wrapperClassName} onClick={handleAfterChange}>
            <a href={url} className="subcategory">
                <h4>
                    <small>
                        <I18n k={`logged_out_homepage.subcategory_carousel.${name}.main`} />
                    </small>
                    <I18n k={subCategoryNameKey} />
                </h4>
                {isTouch ? (
                    <TouchImage subCategoryNameKey={subCategoryNameKey} image={image} pos={pos} />
                ) : (
                    <picture>
                        {picture.map((img, index) => (
                            <source key={index} media={img.media} srcSet={img.srcSet} />
                        ))}
                        <I18nProvider>
                            {({ i18n }) => <img alt={i18n.t(subCategoryNameKey)} src={image.src} />}
                        </I18nProvider>
                    </picture>
                )}
            </a>
        </div>
    );
};

SubCategory.propTypes = {
    name: string,
    categorySlug: string,
    subCategorySlug: string,
    nestedSubCategorySlug: string,
    picture: arrayOf(
        shape({
            media: string,
            srcSet: string,
        })
    ),
    image: shape({
        media: string,
        srcSet: string,
    }),
    index: number,
};

export default SubCategory;
