import React from 'react';
import classNames from 'classnames';
import { arrayOf, number, shape, string } from 'prop-types';
import { getContext, I18n } from '@fiverr-private/fiverr_context';
import { Carousel } from '@fiverr-private/orca';
import SafeComponent from '../../../logged_out_homepage/shared/SafeComponent';
import {
    CLICKED_SUBCATEGORY_ARROW,
    MP_CAROUSEL_CLICKED,
} from '../../../logged_out_homepage/utils/biEvents/events/mixPanelEvents';
import { sendMixPanelEvent } from '../../../logged_out_homepage/utils/biEvents';
import { LOHP_ROW } from '../../../logged_out_homepage/utils/constants';
import SubCategory from './SubCategory';

const SubcategoryCarousel = ({ subCategories }) => {
    const { isTouch } = getContext();
    const enrichedSubcategories = subCategories.map((subCategory) => ({ ...subCategory, isTouch }));

    const handleOnArrowClick = ({ direction }) => {
        sendMixPanelEvent({ eventName: MP_CAROUSEL_CLICKED, params: { element: direction } });
        sendMixPanelEvent({ eventName: CLICKED_SUBCATEGORY_ARROW });
    };

    const breakpoints = {
        [Carousel.BREAKPOINTS.BP_600]: 1,
        [Carousel.BREAKPOINTS.BP_750]: 2,
        [Carousel.BREAKPOINTS.BP_1000]: 3,
        [Carousel.BREAKPOINTS.BP_1300]: 4,
    };

    return (
        <div className={classNames('subcategory-carousel', LOHP_ROW)}>
            <h2>
                <I18n k="logged_out_homepage.subcategory_carousel.title" />
            </h2>
            <Carousel
                swipeMode={isTouch}
                slides={enrichedSubcategories}
                onArrowClick={handleOnArrowClick}
                Component={SubCategory}
                slidesToShow={5}
                breakpoints={breakpoints}
                isInfinite
                scrollType={Carousel.SCROLL_TYPE.ROW}
            />
        </div>
    );
};

SubcategoryCarousel.propTypes = {
    subCategories: arrayOf(
        shape({
            name: string,
            subCategoryId: number,
            nestedSubCategoryId: number,
            categorySlug: string,
            subCategorySlug: string,
            nestedSubCategorySlug: string,
            picture: arrayOf(
                shape({
                    srcSet: string,
                    media: string,
                })
            ),
            image: shape({
                src: string,
                touchSrcSet: string,
                touchSrc: string,
            }),
        })
    ),
};

export { SubcategoryCarousel };
export default SafeComponent(SubcategoryCarousel);
