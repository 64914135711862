import React from 'react';
import { string, number } from 'prop-types';
import { I18nProvider } from '@fiverr-private/fiverr_context';
import pathfinder from '@fiverr-private/pathfinder';
import { sendBigQueryEvent, sendMixPanelEvent } from '../../../../logged_out_homepage/utils/biEvents';
import { BQ_CLICKED_GUEST_HOMEPAGE_CATEGORY_IMAGE } from '../../../../logged_out_homepage/utils/biEvents/events/bigQueryEvents';
import { MP_CATEGORIES_ICON_CLICKED } from '../../../../logged_out_homepage/utils/biEvents/events/mixPanelEvents';

import './style.scss';

const getCategoryUrl = (categorySlug, pos) =>
    pathfinder(
        'short_category',
        { id: categorySlug },
        {
            query: {
                source: 'hplo_cat_sec',
                pos,
            },
        }
    );

const Card = ({ categoryName, categorySlug, categoryImage, categoryId, position }) => {
    const onClick = () => {
        sendMixPanelEvent({
            eventName: MP_CATEGORIES_ICON_CLICKED,
            params: {
                categoryName,
                position,
            },
        });

        sendBigQueryEvent({
            eventName: BQ_CLICKED_GUEST_HOMEPAGE_CATEGORY_IMAGE,
            params: { id: categoryId, name: categoryName, position },
        });
    };

    return (
        <I18nProvider>
            {({ i18n }) => {
                const categoryTitle = i18n.t(`logged_out_homepage.categories.${categoryName}`);

                return (
                    <li>
                        <a href={getCategoryUrl(categorySlug, position)} onClick={onClick}>
                            <img src={categoryImage} alt={categoryTitle} loading="lazy" />
                            {categoryTitle}
                        </a>
                    </li>
                );
            }}
        </I18nProvider>
    );
};

Card.propTypes = {
    categoryName: string,
    categorySlug: string,
    categoryImage: string,
    categoryId: number,
    position: number,
};

export default Card;
