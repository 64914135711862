import React from 'react';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import SafeComponent from '../../../logged_out_homepage/shared/SafeComponent';
import { MAX_WIDTH_CONTAINER } from '../../../logged_out_homepage/utils/constants';
import { TrustedBy } from '../TrustedBy';
import { OmrBadge } from './OmrBadge';
import { Header } from './Header';

import './style.scss';

const HeroBannerGerman = () => {
  const { locale } = getContext();

  const isDeLocale = locale === SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE;

  return (
    <div className="hero-wrapper-de">
      {isDeLocale && <OmrBadge />}

      <div className={classNames('hero', MAX_WIDTH_CONTAINER)}>
        <Header />
        <TrustedBy />
      </div>
    </div>
  );
};

export default SafeComponent(HeroBannerGerman);
