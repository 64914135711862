import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

export const OmrBadge = () => (
  <div className={classNames(styles.omrBadge)}>
    <img
      className={classNames(styles.omrImg)}
      src="https://fiverr-res.cloudinary.com/image/upload/v1/attachments/generic_asset/asset/dc85290be3ad9af4029210bce10605d8-1712052764324/omg-badge-2024-q1.svg"
      alt="OMR badge"
    />
  </div>
);
