import { LOCALES, LOCALES_LABELS } from '../../../logged_out_homepage/utils/constants';
import subtitlesSrc from './assets/fiverr_lohp_video_de-DE.vtt';

export default [
    {
        src: subtitlesSrc,
        label: LOCALES_LABELS[LOCALES.de],
        srcLang: LOCALES.de,
    },
];
