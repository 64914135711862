import React from 'react';
import { isEmpty } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import RelatedGuides from '@fiverr-private/related_guides';
import SafeComponent from '../../../logged_out_homepage/shared/SafeComponent';
import { LOCALE_GUIDES } from './config';

import './style.scss';

const FiverrGuides = () => {
  const { locale } = getContext();

  const guides = LOCALE_GUIDES[locale];

  if (isEmpty(guides)) {
    return <></>;
  }

  return (
    <div className="fiverr-guides">
      <RelatedGuides
        guides={guides}
        seeMoreText={translate('logged_out_homepage.fiverr_guides.see_more_guides')}
        seeMoreLink="/resources/guides"
        title={translate('logged_out_homepage.fiverr_guides.title')}
        sourcePageKey="logged_out_home_page"
      />
    </div>
  );
};

export { FiverrGuides };
export default SafeComponent(FiverrGuides);
