import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import { MIXPANEL_PAGE_NAME, BIGQUERY_PAGE_NAME } from '../../../logged_out_homepage/utils/biEvents/constants';

export const videoUrlDe =
    'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/1b15b249ef02333d5aa64d377e6c33a4-1699972817888/FIVERR_1464%20x%20939_30s.mov';
export const videoPlaceholderUrlDe =
    'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/f0cacf722c6a7fadaf7aee19ae1160d7-1699972641564/lohp_video_preview.png';

export const type = 'video/mp4';

export const sourceInfo = {
    videoName: 'Fiverr intro',
    mixpanel: {
        pageName: MIXPANEL_PAGE_NAME,
    },
    bigQuery: {
        pageName: BIGQUERY_PAGE_NAME,
    },
};

export const sourcesByLocale = {
    [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: {
        src: videoUrlDe,
        poster: videoPlaceholderUrlDe,
        type,
    },
    [SUPPORTED_FIVERR_LOCALES_KEYS.ES]: {
        src: 'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/4152775255ab950937446041bfcf0a5a-1726568449729/HFW%20ES%20Subs%2016x9',
        poster: 'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/ef51b45f79342925d5268e0b2377eae8-1704717764992/thumbnail.png',
        type,
    },
    [SUPPORTED_FIVERR_LOCALES_KEYS.FR_FR]: {
        src: 'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/4152775255ab950937446041bfcf0a5a-1726568449721/HFW%20FR%20Subs%2016x9',
        poster: 'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/ef51b45f79342925d5268e0b2377eae8-1704717764992/thumbnail.png',
        type,
    },
    [SUPPORTED_FIVERR_LOCALES_KEYS.PT_BR]: {
        src: 'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/4152775255ab950937446041bfcf0a5a-1726568449715/HFW%20PT%20Subs%2016x9',
        poster: 'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/ef51b45f79342925d5268e0b2377eae8-1704717764992/thumbnail.png',
        type,
    },
    [SUPPORTED_FIVERR_LOCALES_KEYS.IT_IT]: {
        src: 'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/4152775255ab950937446041bfcf0a5a-1726568449719/HFW%20IT%20Subs%2016x9',
        poster: 'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/ef51b45f79342925d5268e0b2377eae8-1704717764992/thumbnail.png',
        type,
    },
    [SUPPORTED_FIVERR_LOCALES_KEYS.NL_NL]: {
        src: 'https://fiverr-res.cloudinary.com/video/upload/t_fiverr_hd/v1/video-attachments/generic_asset/asset/4152775255ab950937446041bfcf0a5a-1726568449725/HFW%20NL%20Subs%2016x9',
        poster: 'https://fiverr-res.cloudinary.com/image/upload/f_auto,q_auto/v1/attachments/generic_asset/asset/ef51b45f79342925d5268e0b2377eae8-1704717764992/thumbnail.png',
        type,
    },
};

export const videoSchema = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: 'Freelance-Experten beauftragen auf Fiverr | Fiverr',
    description: 'Auf Fiverr findest du erfahrene Freelancer für Projekte jeder Art und Größe.',
    thumbnailUrl: videoPlaceholderUrlDe,
    contentUrl: videoUrlDe,
    duration: 'PT0M30S',
    uploadDate: '2023-11-14',
    transcript: [
        'Kinder, es ist Zeit an die Zukunft der Firma zu denken.',
        'Ich werde dich so stolz machen, Papa.',
        'und Fiverr Freelancer zu beauftragen.',
        'Bitte?',
        'Was?',
        'Mit Fiverr ist es so einfach, gute Leute zu finden.',
        'Produktdesigner, Marketingexperten, Grafiker-',
        'Was ist denn mit einem neuen CEO?',
        'CEO!',
        'Nein, keine CEO-Kategorie.',
        'Es ist Zeit.',
        'Jetzt Fiverr Freelancer beauftragen.',
        'Videoeditoren, KI-Experten, Fotografen,',
    ],
};
