import { DEFAULT_FIVERR_LOCALE, SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import pathfinder from '@fiverr-private/pathfinder';

const priorities = {
  dropshipping_agent: {
    key: 'logged_out_homepage.de.top_priorities.priorities.dropshipping_agent',
    link: pathfinder('gig', { id: 'dropshipping-agent' }),
  },
  fortnite_training: {
    key: 'logged_out_homepage.de.top_priorities.priorities.fortnite_training',
    link: pathfinder('category', { id: 'lifestyle/buy/game-coaching/fortnite' }),
  },
  online_services: {
    key: 'logged_out_homepage.de.top_priorities.priorities.online_services',
    link: pathfinder('gig', { id: 'online-business' }),
  },
  discord_moderator: {
    key: 'logged_out_homepage.de.top_priorities.priorities.discord_moderator',
    link: pathfinder('gig', { id: 'discord-moderator' }),
  },
  buy_twitch_overlay: {
    key: 'logged_out_homepage.de.top_priorities.priorities.buy_twitch_overlay',
    link: pathfinder('category', { id: 'graphics-design/buy/graphics-for-streamers/twitch-overlays-screen-packs' }),
  },
  league_of_legends_training: {
    key: 'logged_out_homepage.de.top_priorities.priorities.league_of_legends_training',
    link: pathfinder('category', { id: 'lifestyle/buy/game-coaching/league-of-legends' }),
  },
  fivem_mlo: {
    key: 'logged_out_homepage.de.top_priorities.priorities.fivem_mlo',
    link: pathfinder('gig', { id: 'fivem-mlo' }),
  },
  valorant_training: {
    key: 'logged_out_homepage.de.top_priorities.priorities.valorant_training',
    link: pathfinder('category', { id: 'lifestyle/buy/game-coaching/valorant' }),
  },
  vtuber_model: {
    key: 'logged_out_homepage.de.top_priorities.priorities.vtuber_model',
    link: pathfinder('gig', { id: 'vtuber' }),
  },
  spotify_promotion: {
    key: 'logged_out_homepage.de.top_priorities.priorities.spotify_promotion',
    link: pathfinder('category', { id: 'online-marketing/buy/music-promotion/spotify' }),
  },
  discord_server: {
    key: 'logged_out_homepage.de.top_priorities.priorities.discord_server',
    link: pathfinder('gig', { id: 'discord-server' }),
  },
  sneaker_bot: {
    key: 'logged_out_homepage.de.top_priorities.priorities.sneaker_bot',
    link: pathfinder('gig', { id: 'sneaker-bot' }),
  },
  it_freelancer_jobs: {
    key: 'logged_out_homepage.de.top_priorities.priorities.it_freelancer_jobs',
    link: '/sellers/freelance-jobs/it',
  },
  vrchat_avatar: {
    key: 'logged_out_homepage.de.top_priorities.priorities.vrchat_avatar',
    link: pathfinder('gig', { id: 'vrchat-avatar' }),
  },
  create_twitch_logo: {
    key: 'logged_out_homepage.de.top_priorities.priorities.create_twitch_logo',
    link: pathfinder('gig', { id: 'twitch-logo' }),
  },
  steam_artwork: {
    key: 'logged_out_homepage.de.top_priorities.priorities.steam_artwork',
    link: pathfinder('gig', { id: 'steam' }),
  },
  logo_design: {
    key: 'logged_out_homepage.de.top_priorities.priorities.logo_design',
    link: pathfinder('category', { id: 'graphics-design/buy/creative-logo-design/3d' }),
  },
  facebook_advertising_consulting: {
    key: 'logged_out_homepage.de.top_priorities.priorities.facebook_advertising_consulting',
    link: pathfinder('category', { id: 'online-marketing/buy/social-marketing/facebook' }),
  },
  pixel_art_images: {
    key: 'logged_out_homepage.de.top_priorities.priorities.pixel_art_images',
    link: pathfinder('category', { id: 'graphics-design/buy/digital-illustration/pixel-art' }),
  },
  social_media_marketing_services: {
    key: 'logged_out_homepage.de.top_priorities.priorities.social_media_marketing_services',
    link: pathfinder('category', { id: 'online-marketing/social-marketing/social-media-management' }),
  },
  signature_logo_design: {
    key: 'logged_out_homepage.de.top_priorities.priorities.signature_logo_design',
    link: pathfinder('category', { id: 'graphics-design/buy/creative-logo-design/signature' }),
  },
  ghostwriter_book: {
    key: 'logged_out_homepage.de.top_priorities.priorities.ghostwriter_book',
    link: pathfinder('category', { id: 'writing-translation/book-and-ebook-writing/ghostwriting' }),
  },
  tiktok_advertising: {
    key: 'logged_out_homepage.de.top_priorities.priorities.tiktok_advertising',
    link: pathfinder('category', { id: 'online-marketing/buy/social-media-advertising/tiktok' }),
  },
  design_book_cover: {
    key: 'logged_out_homepage.de.top_priorities.priorities.design_book_cover',
    link: pathfinder('category', { id: 'graphics-design/book-design/cover' }),
  },
  electrical_circuit_diagram: {
    key: 'logged_out_homepage.de.top_priorities.priorities.electrical_&_circuit_diagram',
    link: pathfinder('category', { id: 'programming-tech/electronics-engineering/pcb' }),
  },
  proofreading_german: {
    key: 'logged_out_homepage.de.top_priorities.priorities.proofreading_german',
    link: pathfinder('category', { id: 'writing-translation/buy/proofreading-editing/german' }),
  },
  create_discord_bot: {
    key: 'logged_out_homepage.de.top_priorities.priorities.create_discord_bot',
    link: pathfinder('category', { id: 'programming-tech/buy/chatbots/discord' }),
  },
  youtube_manager: {
    key: 'logged_out_homepage.de.top_priorities.priorities.youtube_manager',
    link: pathfinder('category', { id: 'online-marketing/online-video-marketing/youtube-channel-management' }),
  },
  ghost_producer: {
    key: 'logged_out_homepage.de.top_priorities.priorities.ghost_producer',
    link: pathfinder('category', { id: 'music-audio/producers-composers/ghost-production' }),
  },
  music_visualization: {
    key: 'logged_out_homepage.de.top_priorities.priorities.music_visualization',
    link: pathfinder('category', { id: 'video-animation/music-videos/music-visualization' }),
  },
};

const notDePriorities = [
  priorities.dropshipping_agent,
  priorities.fortnite_training,
  priorities.online_services,
  priorities.discord_moderator,
  priorities.buy_twitch_overlay,
  priorities.league_of_legends_training,
  priorities.fivem_mlo,
  priorities.valorant_training,
  priorities.vtuber_model,
  priorities.spotify_promotion,
  priorities.discord_server,
  priorities.sneaker_bot,
  priorities.vrchat_avatar,
  priorities.create_twitch_logo,
  priorities.steam_artwork,
  priorities.logo_design,
  priorities.facebook_advertising_consulting,
  priorities.pixel_art_images,
  priorities.social_media_marketing_services,
  priorities.signature_logo_design,
  priorities.ghostwriter_book,
  priorities.tiktok_advertising,
  priorities.design_book_cover,
  priorities.electrical_circuit_diagram,
  priorities.create_discord_bot,
  priorities.youtube_manager,
  priorities.ghost_producer,
  priorities.music_visualization,
];

export const prioritiesMapByLocale = {
  [DEFAULT_FIVERR_LOCALE]: [
    priorities.dropshipping_agent,
    priorities.fortnite_training,
    priorities.online_services,
    priorities.discord_moderator,
    priorities.buy_twitch_overlay,
    priorities.league_of_legends_training,
    priorities.fivem_mlo,
    priorities.valorant_training,
    priorities.vtuber_model,
    priorities.spotify_promotion,
    priorities.discord_server,
    priorities.sneaker_bot,
    priorities.it_freelancer_jobs,
    priorities.vrchat_avatar,
    priorities.create_twitch_logo,
    priorities.steam_artwork,
    priorities.logo_design,
    priorities.facebook_advertising_consulting,
    priorities.pixel_art_images,
    priorities.social_media_marketing_services,
    priorities.signature_logo_design,
    priorities.ghostwriter_book,
    priorities.tiktok_advertising,
    priorities.design_book_cover,
    priorities.electrical_circuit_diagram,
    priorities.proofreading_german,
    priorities.create_discord_bot,
    priorities.youtube_manager,
    priorities.ghost_producer,
    priorities.music_visualization,
  ],
  [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: notDePriorities,
  [SUPPORTED_FIVERR_LOCALES_KEYS.ES]: notDePriorities,
  [SUPPORTED_FIVERR_LOCALES_KEYS.FR_FR]: notDePriorities,
  [SUPPORTED_FIVERR_LOCALES_KEYS.PT_BR]: notDePriorities,
  [SUPPORTED_FIVERR_LOCALES_KEYS.IT_IT]: notDePriorities,
  [SUPPORTED_FIVERR_LOCALES_KEYS.NL_NL]: notDePriorities,
};

export const TOP_PRIORITIES_TAG_TEST_ID = 'top-priorities-tag';
export const TOP_PRIORITIES_TITLE_TEST_ID = 'top-priorities-title';
